import BaseTopNav from '@/components/base/baseTopNav';
import BaseUpload from '@/components/base/baseUpload/index';
import FeaturesAutocomplete from '@/components/features/featuresAutocomplete';
import FeaturesSettingTopTitle from '@/components/features/featuresSettingTopTitle';
import { getDictionaryApi, submitFeedbackApi } from '@/services/api/requestApi';
import { Button, Textarea } from '@nextui-org/react';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useReq } from '@/services/net/request';
import { toast } from '@/layouts/components/ToastMessage';
import { history } from '@@/core/history';
import PageEnum from '@/enums/pageEnum';

export default function Mine() {
  const { t } = useTranslation();
  const [form, setForm] = useState<{
    type: string;
    content: string;
  }>({
    type: '',
    content: '',
  });
  const [imgUrls, setImgUrls] = useState([]);

  //提交反馈
  const { run: submitFeedbackReq } = useReq(submitFeedbackApi, {
    manual: true,
    onSuccess: () => {
      toast.success(t('提交成功，感谢您的反馈'));
      history.replace(PageEnum.HELPCENTER);
    },
  });
  const submitFeedback = () => {
    if (!form.type) return toast.error(t('请选择反馈类型'));
    if (!form.content.trim()) return toast.error(t('请输入反馈内容'));
    if (imgUrls.length === 0) return toast.error(t('请上传截图'));
    submitFeedbackReq({ ...form, imgUrls: imgUrls.join(',') });
  };

  return (
    <div>
      <BaseTopNav />
      {/*顶部标题*/}
      <FeaturesSettingTopTitle
        title={t('帮助中心')}
        description={t('提出反馈,我们会做的更好')}
      />
      <div className="px-4 pt-2">
        <div>
          <div className="mt-3">
            <div className="flex items-center font-bold">
              <div className="text-errorColor mr-1">*</div>
              <span className="ml-1">{t('反馈类型')}</span>
            </div>
            <FeaturesAutocomplete
              // 银行名称自动补全组件
              className="!bg-background rounded-md mt-2"
              variant="bordered"
              selectedKey={form?.type}
              itemValue="value"
              itemText="label"
              allowsCustomValue
              onSelectionChange={(value: any) => {
                setForm({
                  ...form,
                  type: value,
                });
              }}
              isI18={true}
              api={getDictionaryApi}
              params={{
                dictType: 'sys_feedback_type',
              }}
              placeholder={t('请选择反馈类型')}
            />
          </div>
          <div className="mt-3">
            <div className="flex items-center font-bold">
              <div className="text-errorColor mr-1">*</div>
              <span className="ml-1">{t('反馈内容')}</span>
            </div>
            <Textarea
              className="mt-2 "
              variant="bordered"
              placeholder={t('请输入反馈内容')}
              disableAnimation
              disableAutosize
              value={form?.content}
              onValueChange={(value) => {
                setForm({
                  ...form,
                  content: value,
                });
              }}
              classNames={{
                input: 'bg-[transparent] h-[80px]',
                inputWrapper:
                  'rounded-md border-1  border-backgroundAuxiliaryColor relative  group-data-[focus=true]:border-primary',
              }}
            />
          </div>

          <div className="mt-3">
            <div className="flex items-center font-bold">
              <div className="text-errorColor mr-1">*</div>
              <span className="ml-1"> {t('上传截图') + ' '}</span>
            </div>
            <div className="mt-2">
              <div className="min-h-[140px]">
                <BaseUpload
                  isMultiple={true}
                  currentUrls={imgUrls}
                  onChange={setImgUrls as any}
                >
                  <div
                    className="bg-backgroundAuxiliaryColor
                     w-full flex justify-center h-[140px] items-center"
                  >
                    {t('上传截图')}
                  </div>
                </BaseUpload>
              </div>
            </div>
          </div>

          <div className="mt-4"></div>
          <Button
            className="mainColorButton w-full mt-4"
            onClick={() => {
              setTimeout(() => {
                submitFeedback();
              });
            }}
          >
            {t('提交')}
          </Button>
        </div>
      </div>
    </div>
  );
}
